import { database } from "../firebase";
import { useStateValue } from "../StateProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import { get, onValue, ref, set, update } from "firebase/database";
import ChatRow from "./ChatRow";
import { v4 as uuidv4 } from "uuid";
import { actionTypes } from "../reducer";
import countryCodeData from "../data/country_phone.json";
import qrCode from "../../src/assets/ot-install-qr-colored.png";

import {
  getNameByPhone,
  getNotifSettingByPhone,
  getPhoneListByGid,
  getTokenByPhone,
  sendPushNotification,
} from "../Utils";

function ChatGroup() {
  const [rooms, setrooms] = useState([]);
  const [contacts, setcontacts] = useState();
  const [{ user, chat, send, config }, dispatch] = useStateValue();
  const [loading, setloading] = useState(true);
  const [searchtext, setsearchtext] = useState("");

  let sendenabled = send.enabled;
  let userselected = send.selected.length > 0;

  useEffect(() => {
    const userInfo = ref(database, `/users/${user.phoneNumber}/`);
    onValue(userInfo, (snapshot) => {
      if (snapshot.exists) {
        let snapVal = snapshot.val();

        let loggedInUserCountryCodeWithPlus = "";
        let loggedInUserCountryCodeNumber = "";
        let loggedInUserCountry = "";

        for (const key in countryCodeData) {
          if (user.phoneNumber.startsWith(countryCodeData[key])) {
            loggedInUserCountryCodeWithPlus = countryCodeData[key];
            loggedInUserCountryCodeNumber = countryCodeData[key].replace(
              "+",
              ""
            );
            loggedInUserCountry = key;
            break;
          }
        }

        const updatedContacts = {};
        if (snapVal.contact) {
          for (let originalNumber in JSON.parse(snapVal.contact)) {
            let newNumber = originalNumber;
            if (originalNumber.startsWith("00")) {
              newNumber = originalNumber.replace("00", "+");
            } else if (originalNumber.startsWith("011")) {
              newNumber = originalNumber.replace("011", "+");
            } else if (originalNumber.startsWith("0")) {
              newNumber = originalNumber.replace(
                "0",
                loggedInUserCountryCodeWithPlus
              );
            } else if (
              originalNumber.startsWith(loggedInUserCountryCodeNumber)
            ) {
              newNumber = "+" + originalNumber;
            } else if (
              !originalNumber.startsWith(loggedInUserCountryCodeWithPlus)
            ) {
              newNumber = loggedInUserCountryCodeWithPlus + originalNumber;
            }

            updatedContacts[newNumber] = JSON.parse(snapVal.contact)[
              originalNumber
            ];
          }
        }
        setcontacts(updatedContacts);
      }
    });
  }, []);

  useEffect(() => {
    const userInfo = ref(database, `/users/${user.phoneNumber}/chats`);
    onValue(userInfo, (snapshot) => {
      if (!snapshot.exists()) {
        setrooms([]);
        setloading(false);
      } else {
        let snapVal = snapshot.val();
        if (snapVal) {
          let myGroups = Object.values(snapVal);
          myGroups.sort(function (x, y) {
            let a = moment(x.updatedAt);
            let b = moment(y.updatedAt);
            return b - a;
          });
          setrooms(myGroups);
          setloading(false);
        }
      }
    });
  }, []);

  const searchChat = (value) => {
    setsearchtext(value);
    // setloading(true);
  };

  const postComment = async () => {
    let userEmail = "shalabh_saxena@yahoo.com";
    let ticker = send.data.refStockQuote
      ? send.data.refStockQuote.map((item) => item.symbol).join(", ")
      : "";
    const stks = send.comment;
    if (stks) {
      let nstks = stks.split(/[\s,]+/).filter((e) => {
        if (e.startsWith("$")) {
          return e;
        }
      });
      if (nstks.length > 0) {
        if (ticker.length > 0) {
          ticker += ",";
        }
        ticker = nstks.join(",");
      }
    }

    ticker = ticker || "NOASSOC";

    console.log("ticker:", ticker);
    var data = {
      request: {
        aisummary: send.data.aisummary,
        title: send.data.newsTitle,
        entityKey: ticker,
        entityUserVal: 1,
        comments: send.comment || "",
        hyperlink1: send.data.newsLink,
        userName: userEmail,
      },
    };

    console.log("data payload:", data);
    console.log('send payload:', send.data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/postComment/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.status >= 200 && response.status < 300) {
      }
    } catch (errors) {
      console.log("errorsPOSTCOMMENT", errors);
    }
  };

  const sendNewsCard = () => {
    postComment();
    let roomid = send.selected;
    const userInfo = ref(database, `/chatrooms/${roomid}/`);
    let stocks = send.comment.split(/[\s,]+/).filter((e) => {
      if (e.startsWith("$")) {
        return e;
      }
    });
    let stocksjoinedstring = stocks.join("&ticker=").replace("$", "");
    let url = `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/stocksQuote?ticker=${stocksjoinedstring}`;
    dispatch({
      type: actionTypes.SEND_NEWS,
      sendenabled: false,
      news: [],
      room: [],
    });
    fetch(url)
      .then((data) => data.json())
      .then((dataJson) => {
        get(userInfo)
          .then((snapshot) => {
            if (!snapshot.exists) {
              throw "doc doesn't exist";
            } else {
              let data = snapshot.val();
              let group = data.group;
              let uuid = uuidv4();
              let messageObj = {
                _id: uuid,
                text: "OpinionTradeApp",
                createdAt: moment().utc().format(),
                type: "news",
                content: {
                  newsTitle: send.data.newsTitle,
                  newsLink: send.data.newsLink,
                  refStock: send.data.refStock || "",
                  refStockQuote: send.data.refStockQuote
                    ? [...send.data.refStockQuote, ...dataJson]
                    : [...dataJson],
                  newsSite: send.data.newsSite,
                  cmt: send.comment || "",
                  aisummary: send.data.aisummary ? send.data.aisummary : "",
                },
                user: {
                  id: user.uid,
                  _id: user.uid,
                  usernumber: "+" + user.phoneNumber.replace(/\D/g, ""),
                },
                readBy: ["+" + user.phoneNumber.replace(/\D/g, "")],
              };
              set(
                ref(database, `/chatrooms/${roomid}/messages/${uuid}/`),
                messageObj
              );

              update(ref(database, `/chatrooms/${roomid}/`), {
                lastmessage: messageObj,
                updatedAt: moment().utc().format(),
              });

              data.users.map((userPhoneNumber, index) => {
                const phoneNumber = userPhoneNumber.number;
                update(
                  ref(database, `/users/${phoneNumber}/chats/${roomid}/`),
                  {
                    updatedAt: moment().utc().format(),
                  }
                );
              });

              dispatch({
                type: actionTypes.SEND_NEWS,
                sendenabled: false,
                news: [],
                room: [],
              });

              dispatch({
                type: actionTypes.SET_SHARED_NEWS,
                news: {
                  newsTitle: "",
                  newsLink: "",
                  newsSite: "",
                },
              });
              dispatch({
                type: actionTypes.SET_COMMENT,
                comment: "",
              });

              const procPushNotification = async () => {
                let myPhone = user.phoneNumber;

                const targetPhones = await getPhoneListByGid(myPhone, roomid);
                // Extract only the numbers from targetPhones
                const phoneNumbers = targetPhones.map((phone) => phone.number);
                phoneNumbers.map(async (phone, index) => {
                  let notiSettings = await getNotifSettingByPhone(phone);
                  let enableNotification = false;
                  if (targetPhones.length > 2) {
                    enableNotification = notiSettings.groupMessage;
                  } else {
                    enableNotification = notiSettings.peerMessage;
                  }

                  if (enableNotification) {
                    const theToken = await getTokenByPhone(phone);
                    const dName = await getNameByPhone(phone, myPhone);
                    theToken.map((token, index) => {
                      sendPushNotification(
                        token,
                        group === true
                          ? dName + " @ " + data.groupName
                          : dName + " shared a news card",
                        group === true
                          ? "🔗 Shared a news card: " + send.data.newsTitle
                          : "🔗 " + send.data.newsTitle,
                        ",",
                        notiSettings.sound,
                        roomid,
                        group === true ? data.groupName : dName,
                        targetPhones,
                        config.OAuthTokenForMessaging.OauthAccessToken
                      );
                    });
                  }
                });
              };

              procPushNotification();
              postComment();
            }
          })
          .catch((error) => {
            console.log("err", error);
          });
      });
  };

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 bg-[#f2f2f2] h-full overflow-y-scroll scrollbar-hide border-r ${
          user ? (chat.enabled ? "" : "rounded-r-3xl") : ""
        } transition-width transition-slowest ease max-w-[340px] z-[100]`}
      >
        <div className="flex justify-center sticky top-0 z-50 bg-[#f2f2f2] py-3">
          <h1 className="font-bold text-2xl text-black mt-5">
            {sendenabled ? "Select Chat" : "Chats"}
          </h1>
        </div>
        <ul className="flex-1 pt-[80px]">
          <div role="status" className="flex justify-center m-10">
            <svg
              aria-hidden="true"
              className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </ul>
      </div>
    );
  }

  if (loading === false && rooms.length === 0) {
    return (
      <div
        className={`flex flex-col flex-1 bg-[#f2f2f2] h-full overflow-y-scroll scrollbar-hide border-r ${
          user ? (chat.enabled ? "" : "rounded-r-3xl") : ""
        } transition-width transition-slowest ease max-w-[340px] z-[100]`}
      >
        <div className="flex justify-center sticky top-0 z-50 bg-[#f2f2f2] py-3">
          <h1 className="font-bold text-2xl text-black mt-5">
            {sendenabled ? "Select Chat" : "Chats"}
          </h1>
        </div>
        <ul className="flex-1 pt-[80px]">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={qrCode}
              loading="lazy"
              style={{ width: "200px", height: "auto" }}
              className="h-full w-full object-cover p-[4px] cursor-pointer "
            />
            <div className="px-[60px] mt-[40px]">
              <p className="text-center text-[20px] font-bold text-[#0e5446]">
                Wanna share with your friends?
              </p>
              <p className="text-center text-[16px] mt-2">
                Scan the QR and download our iOS app to start a conversation.
              </p>
            </div>
          </div>
        </ul>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col flex-1 bg-white h-full overflow-y-scroll scrollbar-hide border-r ${
        user ? (chat.enabled ? "" : "rounded-r-3xl") : ""
      } transition-width transition-slowest ease max-w-[340px] z-[100]`}
    >
      <div className="sticky top-0 z-50 bg-white py-3">
        <h1 className="font-bold text-2xl text-black my-5 ml-3">
          {sendenabled ? "Select Chat" : "Chats"}
        </h1>
        <label className="px-3 mt-5">
          <input
            className="rounded-lg p-4 bg-gray-100 transition duration-200 focus:outline-none focus:ring-2 w-11/12"
            placeholder="Search..."
            value={searchtext}
            onChange={(event) => {
              searchChat(event.target.value);
            }}
          />
        </label>
      </div>
      <ul className="flex-1 overflow-y-scroll">
        {loading ? (
          <div className="flex justify-center items-center">
            <p>Loading...</p>{" "}
            {/* You can replace this with a loading spinner if desired */}
          </div>
        ) : (
          rooms.map((room, index) => (
            <ChatRow
              key={index}
              index={index}
              id={room.id}
              contacts={contacts || []}
              searchtext={searchtext}
            />
          ))
        )}
      </ul>
      {send.enabled && (
        <>
          {/**<div className="">
          <input
            className="p-4 bg-gray-100 transition duration-200 focus:outline-none w-full border-t-2 border-[#1ab394]"
            placeholder="Comment"
            value={send.comment}
            onChange={(event) => {
              setcomment(event.target.value);
            }}
          />
          </div> */}
          <div
            className={`flex justify-center items-center cursor-pointer ${
              userselected ? "bg-[#1ab394]" : "bg-gray-300"
            } py-4`}
            onClick={() => {
              if (userselected) {
                sendNewsCard();
              }
            }}
          >
            <p className={userselected ? "text-white" : "text-gray-500"}>
              Send
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default ChatGroup;
