import { onValue, ref, update, get } from "firebase/database";
import { useEffect, useState, useRef, useCallback, } from "react";
import { database } from "../../firebase";
import Sidebar from "../Sidebar";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import NewsStockSlider from "../../components/NewsStockSlider";
import MarketMovers from "../../components/MarketMovers";
import Header from "../../components/Header";
import "../styles/Overlayblock.css";
import { isMobile } from "react-device-detect";
import qrCode from "../../assets/qr_with_phone.png";

function LatestTab() {
  const [loading, setloading] = useState(true);
  const [news, setnews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [bookmarknews, setbookmarknews] = useState([]);
  const [
    { user, tab, chat, send, sharednews, selectedstocks, search, loginClicked },
    dispatch,
  ] = useStateValue();
  const [storedsymbols, setstoredsymbols] = useState([]);
  const [pageNumber, setPageNumber] = useState(1); // Track the page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [lastList, setLastList] = useState(false);
  const listInnerRef = useRef();
  const [showFetchMore, setShowFetchMore] = useState(false);
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [joinlatestcount, setJoinLatestCount] = useState(0);
  const [joinlatestcountmobile, setJoinLatestCountMobile] = useState(0);
  const [codereceived, setcodereceived] = useState(false);
  const [phonenumber, setphonenumber] = useState("");
  const [code, setcode] = useState("");
  const recaptchaRef = useRef(null);

  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchCount = async () => {
      const countRef = ref(database, "/webanalytics/JoinLatestCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setJoinLatestCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }

      const countRefMobile = ref(database, "/webanalyticsFromMobile/JoinLatestCount");
      const snapshotMobile = await get(countRefMobile);

      if (snapshotMobile.exists()) {
        setJoinLatestCountMobile(snapshotMobile.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }

    };

    fetchCount();
  }, []);

  console.log("joinlatestcount", joinlatestcount);
  useEffect(() => {
    setloading(true);
    setnews([]);
    getNews();
  }, [tab, selectedstocks]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    if (user) {
      const userInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        userInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setreadnews([]);
            setbookmarknews([]);
          } else {
            let snapVal = snapshot.val();
            if (
              snapVal.bookmarknews !== undefined &&
              snapVal.bookmarknews !== null
            ) {
              setreadnews(Object.keys(snapVal.readnews));
              setbookmarknews(Object.keys(snapVal.bookmarknews));
            }
          }
        },
        (err) => {
          setreadnews([]);
          setbookmarknews([]);
        }
      );
      const watchlistInfo = ref(
        database,
        `/users/${user.phoneNumber}/watchlist`
      );
      onValue(
        watchlistInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setstoredsymbols([]);
          } else {
            let snapVal = snapshot.val();
            if (snapVal !== undefined && snapVal !== null) {
              setstoredsymbols(Object.keys(snapVal));
            }
          }
        },
        (err) => {
          console.log("error getting watchlist", err);
          setstoredsymbols([]);
        }
      );
    } else {
      // dispatch({
      //   type: actionTypes.SET_TAB,
      //   tab: 2,
      // });
      setreadnews([]);
      setbookmarknews([]);
    }
    getNews();
  }, [user]);

  useEffect(() => {
    if (!search.enabled) {
      getNews();
    }
  }, [search.enabled]);

  const getNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=pubTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    setloading(true);
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.length > 0) {
          setnews(respJson);
          //setHasMore(true);
        } else {
          setnews([]);
        }
        setloading(false);
      })
      .catch((error) => {
        setnews([]);
        setloading(false);
        //setHasMore(false);
      });
  };

  useEffect(() => {
    // Trigger the login prompt when the user is not logged in
    if (!user) {
      setShowLoginPrompt(true);
    } else {
      setShowLoginPrompt(false);
    }
  }, [user]);

  const getMoreNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=pubTime&pageNumber=${pageNumber}&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    //setloading(true);
    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (!respJson.length) {
          setLastList(true);
          return;
        }
        if (news.length === 0) {
          setHasMoreNews(false);
        } else {
          setPrevPage(pageNumber);
          setnews((prevNews) => [...prevNews, ...respJson]);
          setloading(false);
          //setShowFetchMore(false);
        }
      })
      .catch((error) => {
        setnews([]);
        setloading(false);
        setLastList(false);
      });
  };

  const onScroll = (e) => {
    const currentScroll = e.target.scrollTop;
    setScrollPosition(currentScroll);

    // Trigger login prompt on scroll past a certain point, e.g., 100px
    if (!user && currentScroll > 500) {
      setShowLoginPrompt(true);
    }

    if (user) {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight && hasMoreNews) {
          setShowFetchMore(true);
          setPageNumber(pageNumber + 1);
          getMoreNews();
        }
      }
    }
  };

  useEffect(() => {
    if (isMobile && codereceived) {
      setnumberloading(false);
    }
  }, [isMobile, codereceived]);

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 overflow-y-scroll scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
          user ? "" : "rounded-r-3xl"
        }`}
      >
        <MarketMovers storedsymbols={storedsymbols} />
        {<Sidebar mobile={true} />}
        {/*renderDisplayNews()*/}
        <Header
          title="Latest News"
          icon={
            <svg
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="#0d5446"
            >
              <path d="M240.5 224H352C365.3 224 377.3 232.3 381.1 244.7C386.6 257.2 383.1 271.3 373.1 280.1L117.1 504.1C105.8 513.9 89.27 514.7 77.19 505.9C65.1 497.1 60.7 481.1 66.59 467.4L143.5 288H31.1C18.67 288 6.733 279.7 2.044 267.3C-2.645 254.8 .8944 240.7 10.93 231.9L266.9 7.918C278.2-1.92 294.7-2.669 306.8 6.114C318.9 14.9 323.3 30.87 317.4 44.61L240.5 224z" />
            </svg>
          }
        />
        <div className="flex justify-center items-center h-screen">
          {/* Spinner */}
          <svg
            role="status"
            className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08136 50.5908C9.08136 73.1895 27.4013 91.5095 50 91.5095C72.5987 91.5095 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67217 50 9.67217C27.4013 9.67217 9.08136 27.9921 9.08136 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7232 75.2124 7.41289C69.5422 4.10259 63.2754 1.94025 56.7222 1.05125C51.7664 0.367118 46.7345 0.446408 41.8192 1.27873C39.297 1.69443 37.8266 4.19778 38.4637 6.62326C39.1008 9.04874 41.5888 10.4711 44.0875 10.1071C47.8291 9.52487 51.6295 9.52678 55.3674 10.1123C60.8745 10.9557 66.1352 13.0003 70.7281 16.0997C75.321 19.1991 79.149 23.2737 81.8832 28.0785C84.1122 31.9221 85.7086 36.1578 86.603 40.571C87.1583 43.0158 89.5421 44.6366 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <p className="text-gray-600 mt-4">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex-1 scrollbar bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
        user ? "" : "sm:rounded-r-3xl"
      } ${
        user
          ? send.enabled
            ? "overflow-y-hidden"
            : "overflow-y-hidden"
          : "overflow-y-hidden"
      }`}
      style={{
        maxHeight: "100vh",
        overflowY: /*user ? */ "scroll" /*: "hidden"*/,
      }}
      onScroll={onScroll}
      ref={listInnerRef}
    >
      <>
        <MarketMovers storedsymbols={storedsymbols} />
        {<Sidebar mobile={true} />}
        <Header
          title="Latest News"
          icon={
            <svg
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="#0d5446"
            >
              <path d="M240.5 224H352C365.3 224 377.3 232.3 381.1 244.7C386.6 257.2 383.1 271.3 373.1 280.1L117.1 504.1C105.8 513.9 89.27 514.7 77.19 505.9C65.1 497.1 60.7 481.1 66.59 467.4L143.5 288H31.1C18.67 288 6.733 279.7 2.044 267.3C-2.645 254.8 .8944 240.7 10.93 231.9L266.9 7.918C278.2-1.92 294.7-2.669 306.8 6.114C318.9 14.9 323.3 30.87 317.4 44.61L240.5 224z" />
            </svg>
          }
        />
        <ul className="mt-0 relative">
          {sharednews.newsTitle && (
            <NewsCard
              key={"sharednews"}
              news={sharednews}
              bookmarknews={bookmarknews}
              readnews={readnews}
              storedsymbols={storedsymbols}
            />
          )}
          {news &&
            news.map((newsItem, index) => (
              <div key={index} className={`${
                index >= 3 && !user ? "blurred-item" : ""
              } transition-all duration-300`}>
                <NewsCard
                  news={newsItem}
                  bookmarknews={bookmarknews}
                  readnews={readnews}
                  storedsymbols={storedsymbols}
                />

                {/* Insert "Login to view the rest!" after the third item */}
                {index === 2 && !user && (
                  <div className="flex justify-center items-center my-4 text-gray-600">
                  <button
                    onClick={() => {
                      if (!isMobile) {
                        dispatch({
                          type: actionTypes.SET_LOGIN_CLICKED,
                          loginClicked: true,
                        });
                      }else{
                        dispatch({
                          type: actionTypes.SET_TAB,
                          tab: 7,
                        });
                      }
                  }}
                  style={{
                    marginTop: 20,
                    padding: "10px 20px",
                    backgroundColor: "#008000",
                    color: "#fff",
                    border: "none",
                    borderRadius: 20,
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                >
                  Click Here to view the rest!
                </button>
                 </div>
                )}
                {/* Only render "Fetching more news..." after the last news item */}
                {index === news.length - 1 && hasMoreNews && !lastList && (
                  <div className="flex flex-col justify-center items-center py-4 text-gray-500">
                    <div>Fetching more news...</div>
                    <svg
                      role="status"
                      className="inline w-10 h-10 mt-4 text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9765 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9765 100 50.5908ZM9.08136 50.5908C9.08136 73.1895 27.4013 91.5095 50 91.5095C72.5987 91.5095 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67217 50 9.67217C27.4013 9.67217 9.08136 27.9921 9.08136 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5532C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7232 75.2124 7.41289C69.5422 4.10259 63.2754 1.94025 56.7222 1.05125C51.7664 0.367118 46.7345 0.446408 41.8192 1.27873C39.297 1.69443 37.8266 4.19778 38.4637 6.62326C39.1008 9.04874 41.5888 10.4711 44.0875 10.1071C47.8291 9.52487 51.6295 9.52678 55.3674 10.1123C60.8745 10.9557 66.1352 13.0003 70.7281 16.0997C75.321 19.1991 79.149 23.2737 81.8832 28.0785C84.1122 31.9221 85.7086 36.1578 86.603 40.571C87.1583 43.0158 89.5421 44.6366 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <p className="text-gray-600 mt-4">Loading...</p>
                  </div>
                )}
              </div>
            ))}
        </ul>
      </>
      {/* Login Prompt Overlay - Appears from bottom and covers half the screen */}
      {showLoginPrompt && !isMobile ? (
      <div className={isMobile ? "login-overlay-mobile animate-slide-up" : "login-overlay animate-slide-up"}>
        <div 
          className="login-overlay-content" 
          style={{ marginRight: "100px", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}
        >
                {/* Sign-up Button */}
        <div style={{ marginRight: "150px", marginTop: "80px" }}>
          <button
            onClick={() => {
              if (!isMobile) {
                setJoinLatestCount((prevCount) => {
                  const newCount = prevCount + 1;

                  // Update Firebase with the new count
                  update(ref(database, `/webanalytics/`), {
                    JoinLatestCount: newCount,
                  })
                    .then(() => {
                      console.log("Firebase updated successfully");
                    })
                    .catch((error) => {
                      console.error("Error updating Firebase:", error);
                    });
                  return newCount; // Ensure the state is updated correctly
                });

                dispatch({
                  type: actionTypes.SET_LOGIN_CLICKED,
                  loginClicked: true,
                });
              } else {
                setJoinLatestCountMobile((prevCount) => {
                  const newCount = prevCount + 1;

                  // Update Firebase with the new count
                  update(ref(database, `/webanalyticsFromMobile/`), {
                    JoinLatestCount: newCount,
                  })
                    .then(() => {
                      console.log("Firebase updated successfully");
                    })
                    .catch((error) => {
                      console.error("Error updating Firebase:", error);
                    });
                  return newCount; // Ensure the state is updated correctly
                });
                dispatch({
                  type: actionTypes.SET_TAB,
                  tab: 7
                });
              }
            }}
            style={{
              padding: "10px 20px",
              backgroundColor: "#008000",
              color: "#d1fae5",
              border: "none",
              borderRadius: 20,
              fontSize: "1rem",
              cursor: "pointer",
            }}
          >
            Sign up In Seconds!
          </button>

          <div>
            <div ref={recaptchaRef}></div>
          </div>
        </div>

        {/* Statements */}
        {!isMobile && (
          <div>
            <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#d1fae5",
                marginTop: "60px"
              }}
            >
              🚀 Welcome to the Future of Investing!
            </h2>
            <ul
              style={{
                fontSize: "1rem",
                lineHeight: 1.6,
                listStyleType: "none",
                padding: 0,
                color: "#d1fae5",
              }}
            >
              <li> <strong> Why should you sign up? </strong> </li>
              <li>✅ <strong>No cost, no risk</strong> – Just smarter investing!</li>
              <li>✅ <strong>See why stocks are moving</strong> – before the market reacts</li>
              <li>✅ <strong>Share and discuss insights</strong> with investors in real time</li>
              <li>✅ <strong>Instant AI summaries</strong> of breaking stock news</li>
            </ul>
          </div>
        )}

          {/* Right Side: Download App */}
          <div style={{ flexShrink: 0, textAlign: "center", marginLeft: "140px" }}>
            <p className="font-handwritten text-[25px] pt-2 text-center" style={{ color: "#d1fae5" }}>
              Download our app
            </p>
            <div className="flex">
              <div className="flex flex-1 justify-center items-center space-x-4">
                <a
                  href="https://apps.apple.com/us/app/opiniontrade/id1530699357"
                  target="_blank"
                >
                  <img
                    src={qrCode}
                    loading="lazy"
                    style={{
                      width: "200px",
                      height: "200px",
                    }}
                    className="h-auto w-[250px] object-contain cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      showLoginPrompt && (
        <div className={isMobile ? "login-overlay-mobile animate-slide-up" : "login-overlay animate-slide-up"}>
          <div className="login-overlay-content">
            <button
              onClick={() => {
                if (!isMobile) {
                  setJoinLatestCount((prevCount) => {
                    const newCount = prevCount + 1;

                    // Update Firebase with the new count
                    update(ref(database, `/webanalytics/`), {
                      JoinLatestCount: newCount,
                    })
                      .then(() => {
                        console.log("Firebase updated successfully");
                      })
                      .catch((error) => {
                        console.error("Error updating Firebase:", error);
                      });
                    return newCount; // Ensure the state is updated correctly
                  });

                  dispatch({
                    type: actionTypes.SET_LOGIN_CLICKED,
                    loginClicked: true,
                  });
                } else {
                  setJoinLatestCountMobile((prevCount) => {
                    const newCount = prevCount + 1;

                    // Update Firebase with the new count
                    update(ref(database, `/webanalyticsFromMobile/`), {
                      JoinLatestCount: newCount,
                    })
                      .then(() => {
                        console.log("Firebase updated successfully");
                      })
                      .catch((error) => {
                        console.error("Error updating Firebase:", error);
                      });
                    return newCount; // Ensure the state is updated correctly
                  });
                  dispatch({
                    type: actionTypes.SET_TAB,
                    tab: 7
                  });
                }
              } }
              style={{
                marginTop: 20,
                padding: "10px 20px",
                backgroundColor: "#008000",
                color: "#fff",
                border: "none",
                borderRadius: 20,
                fontSize: "1rem",
                cursor: "pointer",
              }}
            >
              Click Here to Join OpinionTrade!
            </button>
            {!isMobile ? (
              <>
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Welcome! 🌟 Join The First Social Network For Investors
                  (COMPLETELY FREE - AI POWERED)
                </h2>
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "1rem",
                    lineHeight: 1.5,
                  }}
                >
                  Enter your phone number to connect with your friends and start
                  making smarter investment decisions today! Join a community that's
                  already profiting.
                </p>
              </>
            ) : null}
            <div>
              <div ref={recaptchaRef}></div>
            </div>
          </div>
        </div>
      )
    )}
    </div>
  );
}

export default LatestTab;
